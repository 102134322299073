// configs
@import './configs/config';

// universal components
@import './components/buttons';
@import './components/universal';

// sections
@import './components/header';
@import './components/promo';
@import './components/footer';

// desktop promo/billboard
@import './components/billboard';
@import './components/aside';

// popups
@import './components/popup';
@import './components/popup-dark';
@import './components/form';
@import './components/winners';
@import './components/forecast-popup';
@import './components/popup-promotion-end';