.popup {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  display: none;

  width: 100%;
  height: 100%;

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.774);
  &.popup-open {
    display: block;
  }
}

.popup__content {
  max-width: 800rem;
  padding: 16rem 32rem;

  cursor: auto;

  border-radius: 16rem;
  background: rgb(255, 255, 255);

  font-size: 16rem;
}
.popup__text {
  overflow-x: hidden;
  overflow-y: auto;

  max-width: 800rem;
  max-height: 320rem;
  margin-top: 16rem;
  padding-top: 16rem;
  padding-right: 32rem;

  scrollbar-color: #8f8f8f #e9e9e9;
  h3 {
    margin-top: 0;
  }
  p {
    margin: 16rem 0;
  }
  ul {
    list-style: none;
  }

  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #e9e9e9; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    border: none; /* creates padding around scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    background-color: #8f8f8f; /* color of the scroll thumb */
  }
  // padding: 0 1rem;
}

.popup__container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
.simplebar-placeholder {
  display: none;
}

.popup__head {
  position: relative;

  padding: 16rem 0;

  border-bottom: 0.5px solid grey;
  p {
    margin: 0;
  }
}

.popup__close-button {
  @include button-reset;

  position: absolute;
  top: 50%;
  right: 0;

  width: 32rem;
  height: 32rem;

  transform: translateY(-50%);
  &:hover {
    opacity: 0.8;
  }
  &::before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 3.2rem;

    content: '';

    background: #000000;
  }
  &::before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(135deg);
  }
}

@media screen and (max-width: $sm) {
  .popup__content {
    max-width: 100%;
    height: 100%;
    padding: 16rem 15rem 0;

    border-radius: 0;

    font-size: 12rem;
  }
  .popup__head {
    padding-right: 20rem;
  }
  .popup__text {
    max-height: calc(100% - 100rem);
    padding: 0 15rem 0 0;
  }

  .popup__close-button {
    top: 0;

    width: 15rem;

    transform: none;
    &::before,
    &:after {
      height: 2rem;
    }
  }
}
